import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DropdownModel } from '../core';

@Injectable({ providedIn: 'root' })
export class BenefitService extends ApiService {
  constructor(http: HttpClient) {
    super(http, 'Benefit');
  }

  getMultiSelectBenefitProducts() {
    return this.http.get<DropdownModel[]>(`${this.route()}/GetMultiSelectBenefitProducts`);
  }

}
